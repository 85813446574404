import React from "react"
import { Link } from "gatsby"

import service1Icon from "../../assets/images/icons/web-design.png"
import service2Icon from "../../assets/images/icons/mobile-design.png"
import service3Icon from "../../assets/images/icons/backend-design2.png"
import service4Icon from "../../assets/images/icons/integration.png"
import service5Icon from "../../assets/images/icons/migration.png"
import service7Icon from "../../assets/images/icons/security.png"
import service8Icon from "../../assets/images/icons/monitoring.png"
import service9Icon from "../../assets/images/icons/devops.png"

const ServicesOne = () => {
  return (
    <section className="solutions-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <h2>Every good solution needs a stable foundation</h2>
          <p>
            All IT systems have their birth, adolescence, maturity, decline, and
            also end of life. Proper architecture allows us to bring new
            features into software systems faster and more economically
            throughout the entire life cycle.
          </p>
          <h4>
            We can adapt an existing system or design a new one from ground up.
          </h4>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service1Icon} alternative="" width="65" />
                <br />
              </div>
              <h3>
                <Link to="/sd-web-architecture">Web Architecture Design</Link>
              </h3>
              <p>
                Design of GUI Application using{" "}
                <strong>Angular, React, Vue</strong> technologies. Porting
                existing web apps from PHP, UI5, JSP to Angular, React, Vue
                technologies.
              </p>
              <Link className="view-details-btn" to="/sd-web-architecture">
                View Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <img
                  src={service2Icon}
                  alternative="mobile design"
                  width="65"
                />
              </div>
              <h3>
                <Link to="/sd-mobile-architecture">
                  Mobile Architecture Design
                </Link>
              </h3>
              <p>
                Design for Mobile Android and iOS Apps using either native
                Development in <strong>Java/Kotlin/Swift</strong> or Hybrid
                Development for both platforms with{" "}
                <strong>React-Native</strong>. Porting existing Cordova,
                Phone-gap Hybrid apps to native development or react-native.
              </p>
              <Link className="view-details-btn" to="/sd-mobile-architecture">
                View Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <img
                  src={service3Icon}
                  alternative="backend integration"
                  width="70"
                />
              </div>

              <h3>
                <Link to="/sd-backend-architecture">
                  Backend Architecture Design
                </Link>
              </h3>

              <p>
                Microservices Design for backend modules in{" "}
                <strong>Java Spring-Boot</strong> or{" "}
                <strong>NodeJS TypeScript</strong> technologies. Porting
                existing application from JEE, C++ to Spring-Boot. Domain design
                for SQL and NoSQL databases. Secure API Design.
              </p>

              <Link className="view-details-btn" to="/sd-backend-architecture">
                View Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <img
                  src={service4Icon}
                  alternative="backend integration"
                  width="70"
                />
              </div>

              <h3>
                <Link to="/sd-backend-integration">
                  Backend Module Integration
                </Link>
              </h3>

              <p>
                Design of interfaces between micro-services and project external
                entities like clients 3rd party systems, internal business core
                systems, monitoring, Business Intelligence, etc.
              </p>

              <Link className="view-details-btn" to="/sd-backend-integration">
                View Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <img
                  src={service5Icon}
                  alternative="backend integration"
                  width="72"
                />
              </div>

              <h3>
                <Link to="/sd-migration-replication">
                  Migration & Replication
                </Link>
              </h3>

              <p>
                Design for initial migration of data at productive start from
                legacy systems or manual entry. Design for release upgrade
                migration of existing data structures. Replication of business
                data for reconciliation or data warehousing.
              </p>

              <Link className="view-details-btn" to="/sd-migration-replication">
                View Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                {/* <i className="flaticon-analysis"></i> */}
                <img src={service8Icon} width="70" />
              </div>

              <h3>
                <Link to="/sd-system-monitoring">System Monitoring Design</Link>
              </h3>

              <p>
                Production Monitoring with aggregated logging and altering in
                distributed systems. Analytical and audit events gathering from
                frontend mobile and backend modules.
              </p>

              <Link className="view-details-btn" to="/sd-system-monitoring">
                View Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <img
                  src={service7Icon}
                  alternative="backend integration"
                  width="70"
                />
              </div>

              <h3>
                <Link to="/sd-security-design">Security Design</Link>
              </h3>

              <p>
                Central IDM System build, Security Role management and check for
                modules and entire systems. Transport and Data Encryption for
                persistence in all solution layers like Frontend and Backend
                modules.
              </p>

              <Link className="view-details-btn" to="/sd-security-design">
                View Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-settings"></i>
              </div>

              <h3>
                <Link to="/sd-api-gateway-design">API Gateway Design</Link>
              </h3>

              <p>
                API Strategy Design for long term external interfaces accessible
                on the Internet. Proper documentation and examples preparation.
                Access rights, limit and api monetisation management.
              </p>

              <Link className="view-details-btn" to="/sd-api-gateway-design">
                View Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service9Icon} alternative="devops" width="70" />
              </div>

              <h3>
                <Link to="/sd-devops-design">DevOps & Reliability Design</Link>
              </h3>

              <p>
                Building a cloud native solution, or adapting an existing one to
                the cloud. Design of globally reliable systems for{" "}
                <strong>24/7</strong> availability in various geographic zones.
              </p>

              <Link className="view-details-btn" to="/sd-devops-design">
                View Details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServicesOne
